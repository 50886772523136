<template>
    <div class="add">
        <!-- <van-nav-bar title="微信" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="dingbukongbai"></div>
        <div class="content">
            <div v-if="!dialogTableVisible" class="content_top" ref="imageWrapper">
                <div class="content_top_kongb"></div>
                <div class="content_top_title">我的{{type ? '驻厂' : '招聘经纪人'}}：{{username}}</div>
                <div class="content_top_img"><img :src="url" alt=""></div>
                <div class="content_top_dibu">请扫码或长按二维码识别添加{{type ? '驻厂' : '招聘经纪人'}}微信</div>
            </div>
            <div class="cancl_tan_On">
                <div class="content_text" v-if="is_null">
                    <div>{{type ? '驻厂' : '招聘经纪人'}}暂未上传微信二维码</div>
                    <div>您可拨打电话联系</div>
                </div>
                <img style="width:100%;height:100%;" v-if="imgUrl" :src="imgUrl" alt="">
            </div>
            <div class="cont_phone">
                <div class="cont_phone_on" v-if="mobile">
                    <div class="cont_phone_on_lt"></div>
                    <a :href="`tel:${mobile}`">
                        <div class="cont_phone_on_rt">{{mobile}}</div>
                    </a>

                </div>
            </div>
            <!-- <p>请扫码或长按二维码识别</p>
            <h2>添加{{type ? '驻厂' : '招聘经纪人'}}微信</h2>
            <div class="code">
                <div class="border1"></div>
                <div class="border2"></div>
                <div class="border3"></div>
                <div class="border4"></div>
                <img :src="url" alt="">
            </div> -->
        </div>
        <div class="bottom_back">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        <!-- <div class="cancl_tan" v-if="dialogTableVisible">
            <div class="cancl_tan_On"><img style="width:100%;height:100%;" :src="imgUrl" alt=""></div>
        </div> -->
    </div>
</template>
<script>
import '@/assets/css/index.css'
import html2canvas from "html2canvas"
export default {
    data() {
        return {
            url: '',
            type: false,
            is_null: false,
            dialogTableVisible: false,
            urltwo: require('../../assets/img/morener.png'),
            imgUrl: '',
            username: '',
            mobile: '',
        };
    },
    mounted() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.url = this.$route.query.img ? this.$route.query.img : this.$store.state.memberInfo.wechat_qrcode
        if (!this.url) {
            this.url = this.urltwo
            this.is_null = true
        } else {
            this.is_null = false
        }
        this.username = this.$route.query.name ? this.$route.query.name : this.$store.state.memberInfo.operator_name
        this.mobile = this.$route.query.phone ? this.$route.query.phone : this.$store.state.memberInfo.mobile
        this.type = this.$route.query.type ? true : false;
        document.title = this.type ? '联系驻厂' : '联系招聘经纪人'
        this.$nextTick(() => {
            this.toImage()
        });
        //this.toImage()
    },
    watch: {
        '$store.state.memberInfo': function () {
            this.imgUrl = ''
            if (!this.$route.query.img) {
                this.url = this.$store.state.memberInfo.wechat_qrcode
                if (!this.url) {
                    this.url = this.urltwo
                    this.is_null = true
                } else {
                    this.is_null = false
                }
                this.dialogTableVisible = false
                setTimeout(() => {
                    this.toImage()
                }, 0)
            }

        }
    },
    methods: {
        toImage() {
            html2canvas(this.$refs.imageWrapper, { scale: 2, useCORS: true }).then(canvas => {
                let dataURL = canvas.toDataURL("image/png");
                this.imgUrl = dataURL;
                if (this.imgUrl !== "") {
                    this.dialogTableVisible = true;
                }
            });
        },
        kssstart() {
            // mymap你要截取的元素 也可以是body  整个页面  **
            let canvasID = this.$refs.mymap;
            let that = this;
            let a = document.createElement('a');
            html2canvas(canvasID, { useCORS: true, scrollY: 0, removeContainer: false }).then(canvas => {
                let dom = document.body.appendChild(canvas);
                dom.style.display = "none";
                a.style.display = "none";
                document.body.removeChild(dom);
                let blob = that.dataURLToBlob(dom.toDataURL("image/png"));
                this.imgUrl = blob;
                if (this.imgUrl !== "") {
                    this.dialogTableVisible = true;
                }
            });
        },
        dataURLToBlob(dataurl) {//ie 图片转格式
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime })
        },
        gobanck() {
            this.$router.go(-1);
        },
        onClickLeft() {
            this.$router.go(-1)
        },
    }

}
</script>
<style scoped>
.add {
    position: relative;
}
.content {
    border-radius: 20px !important;
}
.content_text {
    color: #0abbb9;
    font-weight: 700;
    text-align: center;
    position: absolute;
    width: 100%;
    margin-top: 300px;
    font-size: 32px;
}
.dingbukongbai {
    width: 100%;
    height: 210px;
}
.cancl_tan_On {
    width: 690px;
    height: 760px;
    border-radius: 20px !important;
}

.cancl_tan_On img {
    border-radius: 20px;
}

.cont_phone {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cont_phone_on {
    width: 215px;
    height: 35px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.cont_phone_on_lt {
    width: 32px;
    height: 32px;
    background: url("../../assets/img/dianhua_icon.png");
    background-size: 100% 100%;
    margin-right: 10px;
    display: inline-block;
}
.cont_phone_on_rt {
    width: 170px;
    height: 35px;
    line-height: 35px;
    text-align: right;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0abbb9;
    font-weight: bold;
}
.content_top_kongb {
    width: 100%;
    height: 60px;
}
.content_top_img {
    width: 480px;
    height: 480px;
    margin: 0 auto;
    margin-top: 20px;
}
.content_top_dibu {
    display: inline-block;
    width: 100%;
    line-height: 15px;
    text-align: center;
    padding-bottom: 25px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
}
.content_top_title {
    width: 100%;
    height: 55px;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 40px;
    line-height: 55px;
    text-align: center;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #1a1a1a;
}
.content_top {
    width: 100%;
    height: 760px;
    position: absolute;
    top: -1000px;
    border-bottom: 1px solid #f8f8f8;
}
.add {
    text-align: center;
    /* background: linear-gradient(162deg, #0ABBB9 0%, #FFFFFF 50%, #f8f8f8 100%); */
    background: url("../../assets/img/lianx_zhuc_bg.png");
    background-size: 100% 120%;
    height: 100vh;
    width: 100vw;
}
.content {
    position: relative;

    background: #fff;
    width: calc(100% - 60px);
    height: 880px;
    margin: auto;
    border-radius: 14px;
}
p {
    padding-top: 90px;
    font-size: 32px;
}
h2 {
    font-size: 40px;
    padding-top: 24px;
    padding-bottom: 50px;
}
img {
    width: 388px;
    height: 388px;
    margin: auto;
}
.code {
    position: relative;
    width: 410px;
    height: 410px;
    margin: auto;
    display: flex;
    align-items: center;
}
.code > div {
    border: 6px solid #5c9df5;
    width: 30px;
    height: 30px;
    position: absolute;
}
.code .border1 {
    border-right: 0;
    border-bottom: 0;
    top: 0;
    left: 0;
}
.code .border2 {
    border-left: 0;
    border-bottom: 0;
    top: 0;
    right: 0;
}
.code .border3 {
    border-right: 0;
    border-top: 0;
    bottom: 0;
    left: 0;
}
.code .border4 {
    border-left: 0;
    border-top: 0;
    bottom: 0;
    right: 0;
}
.bottom_back {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
    margin: auto;
}
</style>